import theme from "../theme";
import * as React from "react";
import { styled } from "@mui/material/styles";
import classes from "../styles/components/productivityReport";
import { BrowserView, MobileView } from "react-device-detect";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, Paper, FormControlLabel, Checkbox} from "@mui/material";
import { blue } from '@mui/material/colors';
import { isMobile } from "react-device-detect";

class LineProductivityReport extends React.Component {
  constructor() {
    super();
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
        width: 0,
        height: 0,
        tableView: false,
        showFullLinePath: false,
        order: "asc", // 'asc' or 'desc'
        orderBy: "line_id", // Default sorting column
    };
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
    }

    getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    handleSort = (property) => {
        const isAsc = this.state.orderBy === property && this.state.order === "asc";
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property,
        });
    };

  updateDimensions() {
    if (window.innerWidth < 717) {
      this.setState({
        width: window.innerWidth,
        height: window.innerWidth / 4.4,
        tableView: true,
      });
    } else {
      this.setState({
        width: window.innerWidth,
        height: window.innerWidth,
        tableView: false,
      });
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    // this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  //generates table headings for desktop
  generateRows() {
    const { data } = this.props;
    const {order, orderBy, showFullLinePath} = this.state
    var lineData = data.line_data

    const sortedData = [...lineData].sort(this.getComparator(order, orderBy));

    const headingElement = 
      <TableRow sx={{ backgroundColor: "#065f92" }}>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Line </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "total_items"}
                direction={orderBy === "total_items" ? order : "asc"}
                onClick={() => this.handleSort("total_items")}
            >
                Items Packed
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "weight"}
                direction={orderBy === "weight" ? order : "asc"}
                onClick={() => this.handleSort("weight")}
            >
                Kilograms Packed
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "giveaway"}
                direction={orderBy === "giveaway" ? order : "asc"}
                onClick={() => this.handleSort("giveaway")}
            >
                Giveaway (kg)
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "giveaway_percentage"}
                direction={orderBy === "giveaway_percentage" ? order : "asc"}
                onClick={() => this.handleSort("giveaway_percentage")}
            >
                Giveaway (%)
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "ipm"}
                direction={orderBy === "ipm" ? order : "asc"}
                onClick={() => this.handleSort("ipm")}
            >
                Items Per Minute
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "delta_time"}
                direction={orderBy === "delta_time" ? order : "asc"}
                onClick={() => this.handleSort("delta_time")}
            >
                Active Time (hh:mm)
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "kg_per_hour"}
                direction={orderBy === "kg_per_hour" ? order : "asc"}
                onClick={() => this.handleSort("kg_per_hour")}
            >
                Average kg/h
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "items_underweight"}
                direction={orderBy === "items_underweight" ? order : "asc"}
                onClick={() => this.handleSort("items_underweight")}
            >
                Underweight %
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "items_overweight"}
                direction={orderBy === "items_overweight" ? order : "asc"}
                onClick={() => this.handleSort("items_overweight")}
            >
                Overweight %
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "waste"}
                direction={orderBy === "waste" ? order : "asc"}
                onClick={() => this.handleSort("waste")}
            >
                Waste (kg)
            </TableSortLabel>
        </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}>
            <TableSortLabel
                align="center"
                active={orderBy === "waste_percentage"}
                direction={orderBy === "waste_percentage" ? order : "asc"}
                onClick={() => this.handleSort("waste_percentage")}
            >
                Waste (%)
            </TableSortLabel>
        </TableCell>
    </TableRow>

    const elements = sortedData.map((row, index) => (
        <TableRow key={index}>
            <TableCell align="center" style={classes(theme, isMobile).tableCell}>
                {showFullLinePath ? row.line_path : row.line_id}
            </TableCell>
            <TableCell align="center" style={classes(theme, isMobile).tableCell}>
                {row.total_items}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.weight}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.giveaway}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.giveaway_percentage}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.ipm}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.delta_time}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.kg_per_hour}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.items_underweight}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.items_overweight}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.waste}
            </TableCell>
            <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                {row.waste_percentage}
            </TableCell>
        </TableRow>
    ));

    this.rows = elements;
    this.headings = headingElement;
    }

  //generates table headings for mobile
  generateMobileRows() {
    const { data } = this.props;
    const {showFullLinePath} = this.state
    var lineData = data.line_data
    let elements = []
    let element
    let headingElement

    for (let i = 0; i < data.length; i++){
        element = 
          <div style={{marginBottom:"2em"}}>
            <TableContainer component={Paper}>
              <Table sx={{ maxWidth: 310}}  size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow key={i} sx={{ backgroundColor: "#065f92" }}>
                    <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center" colSpan={2}> {(showFullLinePath ? data[i].line_path : data[i].line)} </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Packed </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].total_items).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Kilograms Packed </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].weight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                </TableBody>   
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Giveaway (kg) </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].giveaway).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Giveaway (%) </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].giveaway_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Per Minute </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].ipm).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>            
              </Table>
            </TableContainer>
          </div>
        elements.push(element)
      }
    this.mobileRows = elements;
  }


  render() {
    const { data } = this.props;
    if (data){
      this.generateRows()
      this.generateMobileRows()
    }
    if (window.innerWidth < 717) {
      this.state.tableView = true;
    }

    return (
      <div style={{ marginLeft: "1em" }}>
        <FormControlLabel
            control = {<Checkbox
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}

              checked = {this.state.showFullLinePath}
              onChange={(e) => {
                this.setState({
                  showFullLinePath: e.target.checked,
                });
              }}
              inputProps = {{ 'aria-label': 'controlled' }} />}
            label={<span style={{ color:"white" }}> Show Full Line Path </span>} 

          />
        <BrowserView>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
              {this.headings}
              </TableHead>
              <TableBody>
                {this.rows}
              </TableBody>
            </Table>
          </TableContainer>
        </BrowserView>
        <MobileView>
          {this.mobileRows}
        </MobileView>
      </div>
    );
  }
}
export default styled(LineProductivityReport)({ theme });
