import theme from "../theme";
import * as React from "react";
import { styled } from "@mui/material/styles";
import classes from "../styles/components/productivityReport";
import { BrowserView, MobileView } from "react-device-detect";
import {Grid, FormControl, Switch, Table, TableBody, TableCell, TableSortLabel, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Checkbox} from "@mui/material";
import { blue } from '@mui/material/colors';
import { isMobile } from "react-device-detect";

class stationOperatorPerformance extends React.Component {
    constructor() {
        super();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            width: 0,
            height: 0,
            tableView: false,
            showFullLinePath: false,
            showWorkerData: false,
            hideUnknownWorkers: false,
            order: "asc", // 'asc' or 'desc'
            orderBy: "line_id", // Default sorting column
        };
    }

    descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    
    getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    handleSort = (property) => {
        const isAsc = this.state.orderBy === property && this.state.order === "asc";
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property,
        });
    };

    updateDimensions() {
        if (window.innerWidth < 717) {
            this.setState({
                width: window.innerWidth,
                height: window.innerWidth / 4.4,
                tableView: true,
            });
        } else {
            this.setState({
                width: window.innerWidth,
                height: window.innerWidth,
                tableView: false,
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    //generates table headings for desktop
    generateRows() {
        const { order, orderBy, showFullLinePath, showWorkerData, hideUnknownWorkers } = this.state;
        const { data } = this.props;
        let lineData = this.state.showWorkerData ? data.worker_data : data.line_data;
        let totals = this.state.showWorkerData ? data.worker_totals : data.line_totals;

        if (showWorkerData && hideUnknownWorkers) {
            lineData = lineData.filter(item => item.worker_name && item.worker_name !== "Unknown");
        
            // Recalculate totals after filtering
            const filteredTotals = lineData.reduce(
                (acc, item) => {
                    const [hours, minutes] = item.delta_time.split(":").map(Number);
                    const itemDeltaTimeInMinutes = hours * 60 + minutes;
        
                    acc.total_active_time += itemDeltaTimeInMinutes; // Active time in minutes
                    acc.total_kg += item.weight || 0; // Add weight
                    acc.total_waste += item.waste || 0; // Add waste
                    return acc;
                },
                { total_active_time: 0, total_kg: 0, total_waste: 0 }
            );
        
            // Format totals for display
            const hours = Math.floor(filteredTotals.total_active_time / 60);
            const minutes = filteredTotals.total_active_time % 60;
            totals = {
                total_active_time: `${hours}:${minutes.toString().padStart(2, "0")}`,
                total_kg: filteredTotals.total_kg.toFixed(2),
                total_waste: filteredTotals.total_waste.toFixed(2),
            };
        }

        // Apply sorting
        const sortedData = [...lineData].sort(this.getComparator(order, orderBy));

        const headingElement = (
            <TableRow
                sx={{
                    backgroundColor: "#065f92",
                    position: "sticky",
                    top: 0, // Sticks to the top of the viewport when scrolling
                    zIndex: 1, // Ensures it stays above other elements
                }}
            >
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    Scale
                </TableCell>
                {this.state.showWorkerData && (
                    <TableCell
                        align="center"
                        style={{
                            ...classes(theme, isMobile).tableHeadings,
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        Operator
                    </TableCell>
                )}
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        align="center"
                        active={orderBy === "weight"}
                        direction={orderBy === "weight" ? order : "asc"}
                        onClick={() => this.handleSort("weight")}
                    >
                        Kg Packed <br /> Total: {totals.total_kg} kg
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "delta_time"}
                        direction={orderBy === "delta_time" ? order : "asc"}
                        onClick={() => this.handleSort("delta_time")}
                    >
                        Active Time <br /> Total: {totals.total_active_time}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "kg_per_hour"}
                        direction={orderBy === "kg_per_hour" ? order : "asc"}
                        onClick={() => this.handleSort("kg_per_hour")}
                    >
                        Average kg/h
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "items_underweight"}
                        direction={orderBy === "items_underweight" ? order : "asc"}
                        onClick={() => this.handleSort("items_underweight")}
                    >
                        Underweight %
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "items_overweight"}
                        direction={orderBy === "items_overweight" ? order : "asc"}
                        onClick={() => this.handleSort("items_overweight")}
                    >
                        Overweight %
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "waste"}
                        direction={orderBy === "waste" ? order : "asc"}
                        onClick={() => this.handleSort("waste")}
                    >
                        Waste <br /> Total: {totals.total_waste} kg
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <TableSortLabel
                        active={orderBy === "waste_percentage"}
                        direction={orderBy === "waste_percentage" ? order : "asc"}
                        onClick={() => this.handleSort("waste_percentage")}
                    >
                        Waste %
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        ...classes(theme, isMobile).tableHeadings,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    First Packed
                </TableCell>
            </TableRow>
        );
        

        const elements = sortedData.map((row, index) => (
            <TableRow key={index}>
                <TableCell
                    align="center"
                    style={classes(theme, isMobile).tableCell}
                >
                    {row.scale_id}
                </TableCell>
                {this.state.showWorkerData && (
                    <>
                        <TableCell
                            align="left"
                            style={{
                                ...classes(theme, isMobile).tableCell,
                                whiteSpace: "nowrap", // Prevent wrapping
                            }}
                        >
                            {row.worker_name ? (
                                <>
                                    {row.worker_name
                                        .toLowerCase()
                                        .split(" ")
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(" ")}{" "}
                                    {row.worker_name.toLowerCase() !== "unknown" && `(${row.worker_id})`}
                                </>
                            ) : (
                                row.worker_id
                            )}
                        </TableCell>
                    </>
                )}
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.weight}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.delta_time}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.kg_per_hour}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.items_underweight}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.items_overweight}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.waste}
                </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}>
                    {row.waste_percentage}
                </TableCell>
                <TableCell
                    align="right"
                    style={classes(theme, isMobile).tableCell}
                >
                    {row.first_packed.split(" ")[0]} {row.first_packed.split(" ")[1]}
                </TableCell>
            </TableRow>
        ));

        this.rows = elements;
        this.headings = headingElement;
    }

    //generates table headings for mobile
    generateMobileRows() {
        const { data } = this.props;
        const { showFullLinePath, showWorkerData, hideUnknownWorkers } = this.state;
        
        let lineData = showWorkerData ? data.worker_data : data.line_data;
        let totals = this.state.showWorkerData ? data.worker_totals : data.line_totals;
        // Filter out unknown workers if the toggle is on
        if (showWorkerData && hideUnknownWorkers) {
            lineData = lineData.filter(item => item.worker_name && item.worker_name !== "Unknown");
        }

        let elements = [];

        for (let i = 0; i < lineData.length; i++){
            elements.push(
                <div style={{marginBottom:"2em"}} key={i}>
                    <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: 310}}  size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow key={i} sx={{ backgroundColor: "#065f92" }}>
                                    <TableCell 
                                        style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                        align="center" 
                                        colSpan={2}
                                    > {(showFullLinePath ? lineData[i].line_path : lineData[i].line_id)} Line Details </TableCell>
                                </TableRow>
                            </TableHead>
                
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Scale Details </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].scale_id)} </TableCell>
                            </TableBody>
                            { showWorkerData && (
                                <React.Fragment>
                                    <TableBody>
                                        <TableCell 
                                            style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                            align="left"> Operator Personal Number </TableCell>
                                        <TableCell 
                                            style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                            align="left"> {lineData[i].worker_id ? lineData[i].worker_id : "None"} </TableCell>
                                    </TableBody>
                                    <TableBody>
                                        <TableCell 
                                            style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                            align="left"> Operator Name and Surname </TableCell>
                                        <TableCell 
                                            style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                            align="left"> {lineData[i].worker_name ? lineData[i].worker_name : "Unknown"} </TableCell>
                                    </TableBody>
                                </React.Fragment>
                            )}
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Kilograms Packed<br /> Total: {totals.total_kg} kg </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].weight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Active Time <br /> Total:{totals.total_active_time} </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].delta_time).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                            </TableBody>   
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Average kg/h </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].kg_per_hour).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                align="left"> % Underweight </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].items_underweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> % Overweight </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].items_overweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Waste <br /> Total: {totals.total_waste} kg </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].waste).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody> 
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> % Waste </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].waste_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> First Packed </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].first_packed)}</TableCell>
                            </TableBody>           
                        </Table>
                    </TableContainer>
                </div>
            );
        }

        this.mobileRows = elements;
    }


    render() {
        const { data } = this.props;

        var lineData = this.state.showWorkerData ? data.worker_data : data.line_data;

        // Filter if hideUnknownWorkers is active and showWorkerData is true
        if (this.state.showWorkerData && this.state.hideUnknownWorkers && lineData) {
            lineData = lineData.filter(item => item.worker_name && item.worker_name !== "Unknown");
        }

        if (lineData){
            this.generateRows();
            this.generateMobileRows();
        }

        if (window.innerWidth < 717) {
            this.state.tableView = true;
        }

        return (
            <div>
                <FormControl
                    component="fieldset"
                    style={classes(theme, isMobile).bulletBox}
                >
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                            <FormControlLabel
                                style = {{marginLeft:"0em"}}
                                label={<span style={{ color:"white" }}> Show Worker Data </span>}
                                control = {
                                    <Switch
                                        sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                                        checked = {this.state.showWorkerData}
                                        onChange={(e) => {
                                            this.setState({
                                                showWorkerData: e.target.checked,
                                                // Reset hideUnknownWorkers if worker data is turned off
                                                hideUnknownWorkers: e.target.checked ? this.state.hideUnknownWorkers : false
                                            });
                                        }}
                                        inputProps = {{'aria-label': 'controlled'}}
                                    />
                                }
                            />
                        </Grid>
                        
                        {this.state.showWorkerData && (
                            <Grid item>
                                <FormControlLabel
                                    style = {{marginLeft:"0em"}}
                                    label={<span style={{ color:"white" }}> Hide Unknown Workers </span>}
                                    control = {
                                        <Switch
                                            sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                                            checked = {this.state.hideUnknownWorkers}
                                            onChange={(e) => {
                                                this.setState({
                                                    hideUnknownWorkers: e.target.checked
                                                });
                                            }}
                                            inputProps = {{'aria-label': 'controlled'}}
                                        />
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormControl>

                <BrowserView>
                    <TableContainer component={Paper}>
                        <div
                            style={{
                                maxHeight: "400px", // Set the max height for vertical scrolling
                                width: window.innerWidth-100, // Allow the table to scale with the screen size
                                overflowY: "auto", // Enable vertical scrolling
                                overflowX: "auto", // Enable horizontal scrolling
                                whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                        >
                            <Table
                                sx={{
                                    tableLayout: "auto", // Prevent table shrinkage
                                }}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    {this.headings}
                                </TableHead>
                                <TableBody>
                                    {this.rows}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </BrowserView>
                <MobileView>
                    {this.mobileRows}
                </MobileView>
            </div>
        );
    }
}
export default styled(stationOperatorPerformance)({ theme });
