import * as d3 from "d3";

export function stackedBarChart(data, {
  hideUnknown,
  x = d => d,                                 // given d in data, returns the (quantitative) x-value
  y = (d, e) => e,                            // given d in data, returns the (ordinal) y-value
  z = () => 1,                                // given d in data, returns the (categorical) z-value
  weight = d => d,                            // given d in data, returns the weight of the bar
  title,                                      // given d in data, returns the title text
  test,                                       
  marginTop = 30,                             // top margin, in pixels
  marginRight = 0,                            // right margin, in pixels
  marginBottom = 0,                           // bottom margin, in pixels
  marginLeft = 60,                            // left margin, in pixels
  width,                                      // outer width, in pixels
  height,                                     // outer height, in pixels
  xType = d3.scaleLinear,                     // type of x-scale
  xDomain,                                    // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yDomain,                                    // array of y-values
  yRange,                                     // [bottom, top]
  yPadding = 0.1,                             // amount of y-range to reserve to separate bars
  zDomain,                                    // array of z-values
  offset = d3.stackOffsetDiverging,           // stack offset method
  order = d3.stackOrderNone,                  // stack order method
  xFormat,                                    // a format specifier string for the x-axis
  xLabel = "Number of Items",                 // a label for the x-axis
  colors = ['#E97451','#FFFF00','#70AD47']    // array of colors
} = {}) {
  // Compute values.
  const X = d3.map(data, x)
  const Y = d3.map(data, y)
  const Z = d3.map(data, z)
  const W = d3.map(data, weight)
  
  let chartRows = X.filter(e => e !== undefined).length/3

  // Compute default y- and z-domains, and unique them.
  if (hideUnknown === true)
    yDomain = new d3.InternSet(yDomain.filter(value => 
      value !== undefined && !value.toLowerCase().includes("unknown")
    ));
  else
    yDomain = new d3.InternSet(yDomain.filter(value =>
      value !== undefined
    ));

  const zValues = zDomain
  zDomain = new d3.InternSet(zDomain);

  // Omit any data not present in the y- and z-domains.
  const I = d3.range(X.length).filter(i => yDomain.has(Y[i]) && zDomain.has(Z[i]));
  
  // If the height is not specified, derive it from the y-domain.
  if (height === undefined) height = yDomain.size * 25 + marginTop + marginBottom;
  if (yRange === undefined) yRange = [height - marginBottom, marginTop];

  // Compute a nested array of series.
  const series = d3.stack()
    .keys(zDomain)
    .value(([, I], z) => X[I.get(z)])
    .order(order)
    .offset(offset)
    (d3.rollup(I, ([i]) => i, i => Y[i], i => Z[i]))
    .map(s => s.map(d => Object.assign(d, {i: d.data[1].get(s.key)})));
  
  // Compute the default x-domain.
  if (xDomain === undefined) xDomain = d3.extent(series.flat(2));
  
  // Construct scales, axes, and formats.
  const xScale = xType(xDomain, xRange);
  const yScale = d3.scaleBand(yDomain, yRange).paddingInner(yPadding);
  const color = d3.scaleOrdinal(zDomain, colors);
  const color_label = d3.scaleOrdinal(zDomain, ['#E97451','#FFFF00','white'])
  
  const xAxis = d3.axisTop(xScale).ticks(width / 80, xFormat);
  const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);
  const format = xScale.tickFormat(100, xFormat);

  // Compute titles.
  if (title === undefined) {
    const formatValue = xScale.tickFormat(100, xFormat);
    title = i => `${formatValue(X[i])}`;
    test = i => `${formatValue(X[i])}`;
  } else {
    const O = d3.map(data, d => d);
    const T = title;
    title = i => T(O[i], i, data);
  }

  // Calculate label positions
  const filteredIndices = Y.map((value, i) => {
    if (value && typeof value === "string" && value.toLowerCase().includes("unknown")) {
      return null; // Exclude "Unknown" entries
    }
    return i;
  }).filter(i => i !== null);

  const realX = X.filter(e => !isNaN(e))
  const n = realX.length/3
  const maxX = Math.max(...realX)
  let nCommas = (format(maxX).split(",").length - 1) + (format(maxX).split(".").length - 1)
  const labelOffset = (format(maxX).length+3 - nCommas*0.8) / 80
  const label_position2 = d3.scaleOrdinal(zDomain, [xRange[1]*(1.15 - labelOffset), xRange[1]*(1.21 - labelOffset), xRange[1]*1.35])
  
  // Create SVG
  const svg = d3.create("svg")
    .attr("width", width+800)
    .attr("height", height+20)
    .attr("viewBox", [100, -30, width-280, height+50])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic; background: #323232;");

  // CHANGES START HERE:
  // Create a group that will hold all chart elements. We'll zoom on this group.
  const chartGroup = svg.append("g");

  // X-axis
  chartGroup.append("g")
    .attr("transform", `translate(0,${marginTop})`)
    .call(xAxis)
    .call(g => g.select(".domain").remove())
    .call(g => g.selectAll(".tick line")
      .attr('stroke', 'white'))
    .call(g => g.selectAll(".tick line").clone()
      .attr("y2", height - marginTop - marginBottom)
      .attr('stroke', 'white')
      .attr("stroke-opacity", 0.2))
    .call(g => g.selectAll(".tick text")
      .style('fill', 'white'))
    .call(g => g.append("text")
      .attr("x", width - marginRight)
      .attr("y", -22)
      .attr("fill", "white")
      .attr("text-anchor", "end")
      .text(xLabel));

  // THE BARS
  const bar = chartGroup.append("g")
    .selectAll("g")
    .data(series)
    .join("g")
    .attr("fill", ([{i}]) => color(Z[i]))
    .selectAll("rect")
    .data(d => d)
    .join("rect")
    .attr("x", ([x1, x2]) => Math.min(xScale(x1), xScale(x2)))
    .attr("y", ({i}) => yScale(Y[i]))
    .attr("width", ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))
    .attr("height", yScale.bandwidth()); 

  bar.append("title")
    .text(({i}) => title(i))

  // VALUES ON BARS
  chartGroup.append("g")
    .attr("text-anchor", "center")
    .attr("font-family", "sans-serif")
    .attr("font-size", 15)
    .selectAll("text")
    .data(I)
    .join("text")
    .attr('fill', i => {
      return X[i] === maxX && Z[i] === zValues[2] ? "white" : color_label(Z[i])
    })
    .attr("text-anchor", "end")
    .attr("x", i => label_position2(Z[i]))
    .attr("y", i => yScale(Y[i]) + yScale.bandwidth() / 2)
    .attr("dy", "0.35em")
    .text((i) => {
        const currentY = Y[i];
        // Find all indices with the same Y value
        const relatedIndices = I.filter(j => Y[j] === currentY);
        
        // Sum all X values for those indices
        const total = d3.sum(relatedIndices, j => X[j]);
        
        const weightInKg = isFinite(W[i]) ? (W[i] / 1000).toFixed(2) : "0.00";
      
        let value;
        if (Z[i] === zValues[0] && total > 0) {
          value = d3.format(",.0%")(X[i] / total); // Items Underweight %
        } else if (Z[i] === zValues[1] && total > 0) {
          value = d3.format(",.0%")(X[i] / total); // Items Overweight %
        } else if (Z[i] === zValues[2]) {
          value = `of ${format(total || 0)}`;
          value += `\u00A0 \u00A0 \u00A0 \u00A0${weightInKg.replace(".", ",")} kg`; 
        }
      
        // If no value was assigned or total is NaN, return "0"
        if (!value || isNaN(total)) value = "0";
        
        return value;
      });

  // Y-axis
  chartGroup.append("g")
    .attr("transform", `translate(${xScale(0)},0)`)
    .call(yAxis)
    .call(g => g.selectAll(".tick line")
      .attr('stroke', 'white'))
    .call(g => g.selectAll(".tick text")
      .style('fill', 'white'));

  // The Legend
  let titles = ["Items Underweight", "Items Overweight", "Items on Weight"]
  let xText = [150, 340, 540]
  let xCircles = [130, 320, 520]
  for (let i=0; i<3; i++){
    chartGroup.append("text")
      .attr("x", xText[i])
      .attr("y", height-height-9)
      .text(titles[i])
      .style("font-size", "13px")
      .style("fill", colors[i])
      .attr("font-family", "sans-serif")
      .attr("alignment-baseline","middle");

    chartGroup.append("circle")
      .attr("cx", xCircles[i])
      .attr("cy", height-height-10)
      .attr("r", 6)
      .style("fill", colors[i]);
  } 

  // ZOOM BEHAVIOR
  const zoom = d3.zoom()
    .scaleExtent([1, 10]) // adjust the scale range as desired
    .translateExtent([[0, 0], [width, height]]) // keep panning within the chart area
    .on("zoom", zoomed);

  function zoomed(event) {
    chartGroup.attr("transform", event.transform);
  }

  svg.call(zoom);

  return {svg: svg.node(), otherStuff: {scales: {color}}, rows:{chartRows}}
}
